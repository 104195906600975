exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prismic-blog-uid-js": () => import("./../../../src/pages/blog/{PrismicBlog.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-uid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-3-d-transform-js": () => import("./../../../src/pages/lab/3d-transform.js" /* webpackChunkName: "component---src-pages-lab-3-d-transform-js" */),
  "component---src-pages-lab-arkit-js": () => import("./../../../src/pages/lab/arkit.js" /* webpackChunkName: "component---src-pages-lab-arkit-js" */),
  "component---src-pages-lab-index-js": () => import("./../../../src/pages/lab/index.js" /* webpackChunkName: "component---src-pages-lab-index-js" */),
  "component---src-pages-lab-jenga-js": () => import("./../../../src/pages/lab/jenga.js" /* webpackChunkName: "component---src-pages-lab-jenga-js" */),
  "component---src-pages-lab-others-pikachu-js": () => import("./../../../src/pages/lab/others/pikachu.js" /* webpackChunkName: "component---src-pages-lab-others-pikachu-js" */),
  "component---src-pages-lab-qwerty-keyboard-js": () => import("./../../../src/pages/lab/qwerty-keyboard.js" /* webpackChunkName: "component---src-pages-lab-qwerty-keyboard-js" */),
  "component---src-pages-lab-sky-splash-js": () => import("./../../../src/pages/lab/sky-splash.js" /* webpackChunkName: "component---src-pages-lab-sky-splash-js" */),
  "component---src-pages-lab-uncanny-counter-beam-js": () => import("./../../../src/pages/lab/uncanny-counter-beam.js" /* webpackChunkName: "component---src-pages-lab-uncanny-counter-beam-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */)
}

